
import { Options, Vue } from 'vue-class-component';
import { CommunityClient } from '@/services/Services';
import CustomConfirm from '@/components/customConfirm.vue';
import store from '@/store';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class AllPeopleModal extends Vue {

    title: string = "";
    people: OM.NameIdentifier[] = [];

    selectedPerson: OM.NameIdentifier = new OM.NameIdentifier;
    showOptionsModal: boolean = false;
    successMessage: string = "";

    created() {
        this.title = store.state.allPeoplePage.title;
        this.people = store.state.allPeoplePage.model;
        this.people = this.people.filter(x => x.name != "Utente eliminato");
    }

    openPerson(item: OM.NameIdentifier) {
        if(item.identifier == store.state.user.identifier)
            return;

        this.selectedPerson = item;
        var canDelete = this.isAdminOrModerator(item.identifier);
        if(canDelete)
            this.showOptionsModal = true;
        else
            this.goToPersonProfile(item.identifier);
    }

    goToPersonProfile(personIdentifier: string) {
        this.$router.push('/profile/' + personIdentifier);
        this.$emit('close');
    }

    isAdminOrModerator(identifier: string) {
        if(identifier == store.state.user.identifier)
            return false;

        if(this.$route.params.notCommunity)
            return false;

        var userIdentifier = store.state.user.identifier;

        var found = store.state.community.admins.find( x => {
            return x.identifier == userIdentifier;
        })

        if(found)
            return true;

        found = store.state.community.moderators.find( x => {
            return x.identifier == userIdentifier;
        })

        if(found)
            return true;

        return false; 
    }

    deleteUser(item: OM.NameIdentifier) {
        var title = LocalizationServices.getLocalizedValue("app_attenzione", "Attenzione!");
        var text = LocalizationServices.getLocalizedValue("app_vuoidavverobloccarequestoutente", "Vuoi davvero bloccare questo utente?");

        this.$opModal.show(CustomConfirm, {
            title: title,
            text: text,
            callback: (value: boolean) => {
                if(!value)
                    this.$opModal.closeLast();
                else {
                    var vm = new OM.DeleteUserVM();
                    vm.userIdentifier = item.identifier;
                    vm.itemIdentifier = store.state.community.identifier;
                    CommunityClient.blockUser(vm)
                    .then( x => {
                        var index = store.state.community.likes.indexOf(item);
                        store.state.community.likes.splice(index, 1);

                        var message = LocalizationServices.getLocalizedValue("app_utentebloccatoconsuccesso", "Utente bloccato con successo!");
                        this.successMessage = message;
                        // window.toastr.success(message);

                        this.showOptionsModal = false;
                        this.$opModal.closeLast();
                    }).catch(err => {
                        // window.toastr.console.error(err);
                        this.$opModal.closeLast();
                    })
                }
            }

        })
    }
    
}

